import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Section, Accordion } from 'src/components';
import { $t, locale } from 'src/utils';

const QUERY = graphql`
  query FaqSectionQueryEnglish {
    en: allGraphCmsFaq(filter: { featured: { eq: true }, locale: { eq: en } }) {
      nodes {
        title
        explanation
      }
    }
    es: allGraphCmsFaq(filter: { featured: { eq: true }, locale: { eq: es } }) {
      nodes {
        title
        explanation
      }
    }
  }
`;

/**
 * FAQs
 * Expandable set of questions and answers
 */
const FAQSection = ({ faqs, color = 'var(--c-cerise-0)', theme = 'dark' }) => {
  const data = useStaticQuery(QUERY);
  const _faqs = !!faqs && faqs?.length > 0 ? faqs : data?.[locale]?.nodes;

  return (
    <Section
      narrow
      bg={theme === 'dark' ? 'black' : 'transparent'}
      dark={theme === 'dark'}
      title={
        <>
          {$t({
            id: 'FAQsection.title-1',
            defaultMessage: 'What’s the catch?',
          })}
          <div style={{ color }}>
            {$t({
              id: 'FAQsection.title-2',
              defaultMessage: "There isn't one.",
            })}
          </div>
        </>
      }
    >
      <Accordion
        faded
        items={faqs?.map((faq) => ({
          title: faq?.title,
          content: faq?.explanation,
        }))}
      />
    </Section>
  );
};

export default FAQSection;
